import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/main/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/compare',
    name: 'compare',
    component: () => import(/* webpackChunkName: "compare" */ '../views/main/compare/compare.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/user/register/register.vue')
  },
  {
    path: '/me',
    name: 'me',
    component: () => import(/* webpackChunkName: "me" */ '../views/user/me/me.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import(/* webpackChunkName: "forgetPassword" */ '../views/user/forgetPassword/forgetPassword.vue')
  },
  {
    path: '/boduan',
    name: 'boduan',
    component: () => import(/* webpackChunkName: "boduan" */ '../views/category/boduan/boduan.vue')
  },
  {
    path: '/categoryList',
    name: 'categoryList',
    component: () => import(/* webpackChunkName: "categoryList" */ '../views/category/categoryList/categoryList.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/search/search.vue')
  },
  {
    path: '/myCart',
    name: 'myCart',
    component: () => import(/* webpackChunkName: "myCart" */ '../views/user/myCart/myCart.vue')
  },
  {
    path: '/footnotes',
    name: 'footnotes',
    component: () => import(/* webpackChunkName: "footnotes" */ '../views/user/footnotes/footnotes.vue')
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import(/* webpackChunkName: "goodsDetail" */ '../views/goodsDetail/goodsDetail.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/user/profile/profile.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
  routes
})

router.beforeEach((to, from, next) => {
  console.log('beforeEach', to.path);
  const token = localStorage.getItem('token');
  // 非首页路由，并且没有token跳转登录
  if (!token && !['home', 'login', 'register', 'forgetPassword'].includes(to.name)) {
    next(`/login?backUrl=${to.path}`);
  } else {
    next();
  }
})

export default router
