import request from '../request';
import store from "@/store/index";
const METHOD = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE'
}
const pathPrefix = 'api/'

Object.freeze(METHOD)


/**
 * 获取行为式验证码
 * @param params
 * @returns {Promise<*>}
 */
 export async function getCaptcha(params) {
	return request.post(`${pathPrefix}system/user/captcha/get`, params);
}

/**
 * 一次校验验证码
 * @param params
 * @returns {Promise<*>}
 */
 export async function getCaptchacheck(params) {
	return request.post(`${pathPrefix}system/user/captcha/check`, params);
}