let state = {
    // 菜单数据
	categoryList: [],
    // 三级菜单 
	categoryListThird: [],
    // 一级菜单 
	categoryBaseList: [],
    // 一级菜单 当前选中id
	categoryBaseCurrentId: '',
    // 二级菜单 当前选中
	categorySubCurrentId: '',
	categorySubCurrentName: '',
	// 购物车数量
	cartNum:0,
}

let mutations = {
	setCategoryList(state, info) {
		state.categoryList = info;
	},
	setCategoryListThird(state, info) {
		state.categoryListThird = info;
	},
	setCategoryBaseList(state, info) {
		state.categoryBaseList = info;
	},
	setCategoryBaseCurrentId(state, info) {
		state.categoryBaseCurrentId = info;
	},
	setCategorySubCurrentId(state, info) {
		state.categorySubCurrentId = info;
	},
	setCategorySubCurrentName(state, info) {
		state.categorySubCurrentName = info;
	},
	setCartNum(state, info) {
		state.cartNum = info;
	},
}

let actions = {

};

export default {
	state,
	mutations,
	actions
}
