/**
 *去除小数点后多余的0
 */
function cutZero(old) {
  //拷贝一份 返回去掉零的新串
  old = old + "";
  let newstr = old;
  //循环变量 小数部分长度
  let leng = old.length - old.indexOf(".") - 1;
  //判断是否有效数
  if (old.indexOf(".") > -1) {
    //循环小数部分
    for (let i = leng; i > 0; i--) {
      //如果newstr末尾有0
      if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
        let k = newstr.lastIndexOf("0");
        //如果小数点后只有一个0 去掉小数点
        if (newstr.charAt(k - 1) == ".") {
          return newstr.substring(0, k - 1);
        } else {
          //否则 去掉一个0
          newstr = newstr.substring(0, k);
        }
      } else {
        //如果末尾没有0
        return newstr;
      }
    }
  }
  return old;
}

/**
 * 验证电子邮箱格式
 */
function email(value) {
  return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value);
}

/**
 * 验证手机格式
 */
function mobile(value) {
  return /^1[23456789]\d{9}$/.test(value)
}

/**
 * 验证URL格式
 */
function url(value) {
  return /^((https|http|ftp|rtsp|mms):\/\/)(([0-9a-zA-Z_!~*'().&=+$%-]+: )?[0-9a-zA-Z_!~*'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-zA-Z_!~*'()-]+.)*([0-9a-zA-Z][0-9a-zA-Z-]{0,61})?[0-9a-zA-Z].[a-zA-Z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-zA-Z_!~*'().;?:@&=+$,%#-]+)+\/?)$/
    .test(value)
}

/**
 * 验证日期格式
 */
function date(value) {
  return !/Invalid|NaN/.test(new Date(value).toString())
}

/**
 * 验证ISO类型的日期格式
 */
function dateISO(value) {
  return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(value)
}

/**
 * 验证十进制数字
 */
function number(value) {
  return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value)
}

/**
 * 验证整数
 */
function digits(value) {
  return /^\d+$/.test(value)
}

/**
 * 验证身份证号码
 */
function idCard(value) {
  return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
    value)
}


/**
 * 金额,只允许2位小数
 */
function amount(value) {
  //金额，只允许保留两位小数
  return /^[1-9]\d*(,\d{3})*(\.\d{1,2})?$|^0\.\d{1,2}$/.test(value);
}

/**
 * 中文
 */
function chinese(value) {
  let reg = /^[\u4e00-\u9fa5]+$/gi;
  return reg.test(value);
}

/**
 * 只能输入字母
 */
function letter(value) {
  return /^[a-zA-Z]*$/.test(value);
}

/**
 * 只能是字母或者数字
 */
function enOrNum(value) {
  //英文或者数字
  let reg = /^[0-9a-zA-Z]*$/g;
  return reg.test(value);
}

/**
 * 验证是否包含某个值
 */
function contains(value, param) {
  return value.indexOf(param) >= 0
}

/**
 * 验证一个值范围[min, max]
 */
function range(value, param) {
  return value >= param[0] && value <= param[1]
}

/**
 * 验证一个长度范围[min, max]
 */
function rangeLength(value, param) {
  return value.length >= param[0] && value.length <= param[1]
}

/**
 * 是否固定电话
 */
function landline(value) {
  let reg = /^\d{3,4}-\d{7,8}(-\d{3,4})?$/;
  return reg.test(value);
}

/**
 * 判断是否为空
 */
function empty(value) {
  switch (typeof value) {
    case 'undefined':
      return true;
    case 'string':
      if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
      break;
    case 'boolean':
      if (!value) return true;
      break;
    case 'number':
      if (0 === value || isNaN(value)) return true;
      break;
    case 'object':
      if (null === value || value.length === 0) return true;
      for (var i in value) {
        return false;
      }
      return true;
  }
  return false;
}

/**
 * 是否json字符串
 */
function jsonString(value) {
  if (typeof value == 'string') {
    try {
      var obj = JSON.parse(value);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}


/**
 * 是否数组
 */
function array(value) {
  if (typeof Array.isArray === "function") {
    return Array.isArray(value);
  } else {
    return Object.prototype.toString.call(value) === "[object Array]";
  }
}

/**
 * 是否对象
 */
function object(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

/**
 * 是否短信验证码
 */
function code(value, len = 6) {
  return new RegExp(`^\\d{${len}}$`).test(value);
}

/**
 * 判断是否是数字
 */
function isNumber(value) {
  if (isNaN(value)) {
    return false;
  } else {
    return true;
  }
}

/**
 * 数组对象根据某一字段排序
 * @param property  字段名
 * @param sort  排序规则   asc：升序   desc：降序
 */
function sortKeyByArrayObject(property, sort = 'asc') {
  return function (a, b) {
    const value1 = a[property];
    const value2 = b[property];
    return sort === 'asc' ? value1 - value2 : value2 - value1;
  }
}

/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {function(): void}
 */
function throttle(fn, interval) {
  var enterTime = 0; //触发的时间
  var gapTime = interval || 300; //间隔时间，如果interval不传值，默认为300ms
  return function() {
    var that = this;
    var backTime = new Date(); //第一次函数return即触发的时间
    if(backTime - enterTime > gapTime) {
      fn.call(that, arguments);
      enterTime = backTime; //赋值给第一次触发的时间 保存第二次触发时间
    }
  };
}

/**
 * 函数防抖
 * @param fn
 * @param interval
 * @returns {function(): void}
 */
function debounce(fn, interval) {
  var timer;
  var gapTime = interval || 1000; //间隔时间 不传值默认为1000ms
  return function() {
    clearTimeout(timer);
    var that = this;
    var args = arguments; //保存arguments setTimeout是全局的 arguments不是防抖函数需要的
    timer = setTimeout(function() {
      fn.call(that, args);
    }, gapTime);
  };
}
// js判断数组中是否存在重复的元素
function confirmRepeat(someArray) {
  let tempArray = someArray.slice(0); //复制数组到临时数组
  for (var i = 0; i < tempArray.length; i++) {
    for (var j = i + 1; j < tempArray.length;) {
      if (tempArray[j] == tempArray[i])
        //后面的元素若和待比较的相同，则删除并计数；
        //删除后，后面的元素会自动提前，所以指针j不移动
      {
        return true;
      }
      else {
        j++;
      }
      //不同，则指针移动
    }
  }
  return false;
}


function throttles(fn, interval) {
  var enterTime = 0; //触发的时间
  var gapTime = interval || 300; //间隔时间，如果interval不传值，默认为300ms

  return function() {
    var that = this;
    var backTime = new Date(); //第一次函数return即触发的时间
    console.log('arguments',arguments)
    let [,item] = Array.prototype.slice.call(arguments)
    if (item) {
      let {couponStatus} = item

      if (couponStatus > 3 && couponStatus !=9 ) {return}
      else if(couponStatus == 3) {
        fn.call(that, arguments);
        return
      }
    }
    if(backTime - enterTime > gapTime) {
      fn.call(that, arguments);
      enterTime = backTime; //赋值给第一次触发的时间 保存第二次触发时间
    }else{
      uni.showToast({
        title: '操作太快啦,请稍后重试哦～',
        icon: 'none'
      })
    }
  };
}

module.exports = {
  cutZero,
  email,
  mobile,
  url,
  date,
  dateISO,
  number,
  digits,
  idCard,
  amount,
  chinese,
  letter,
  enOrNum,
  contains,
  range,
  rangeLength,
  empty,
  isEmpty: empty,
  jsonString,
  landline,
  object,
  array,
  code,
  isNumber,
  sortKeyByArrayObject,
  throttle,
  debounce,
  confirmRepeat,
  throttles
}