let state = {
	userInfo: {},
	/* 判断是否登录 */
	loginSign:false,
}

let mutations = {
	setUserInfo(state, info) {
		state.userInfo = Object.assign({},info)  || '';
	},
	setLoginSign(state, info){
		state.loginSign = info;
	},
}

let actions = {

};

export default {
	state,
	mutations,
	actions
}
