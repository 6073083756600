import {isEmpty} from "@/utils/util";


import {
  mapState,
  mapGetters,
  mapMutations
} from 'vuex'
export default {
  name: "Home",
  data() {
    return {
      categoriesList: []
    }
  },

  created() {
  },
  computed: {
    ...mapState({
      categoryBaseList: (state) => state.category.categoryBaseList,
    }),

  },
  mounted() {
    // console.log(this.categoryBaseList);
    // this.categoriesList = this.categoryBaseList;

  },
  methods: {
    ...mapMutations(['setCategoryBaseCurrentId']),

    /**
     * 跳转波段
     * @param item 每个Item数据项
     */
    goBoduan(item) {
      this.setCategoryBaseCurrentId(item.id)
      const token = localStorage.getItem('token');
      if (token) {
        this.$router.push({
          path: "/boduan",
          query: {
            // item: item.name,
            // categoryId: item.id,
          }
        })
      } else {
        this.$router.push('/login?backUrl=boduan')
      }
    }
  }
}