<template>
  <div>
    <header class="header flex-between">
      <!-- 头部左侧 -->
      <div class="left flex">
        <!-- 菜单导航 -->
        <div class="category-div flex" v-if="categoryDivFlag&&categoriesList.length>0">
          <div
            class="category-item"
            v-for="(item, index) in categoriesList"
            :class="item.id == categoryBaseCurrentId &&  categoryItemCheckeFlag ? 'category-item-checked' : ''"

            :key="index"
            @click="categoryClick(item,index)"
          >
            {{ item.name }}
            <!-- <div class="category-item-name"> </div> -->
          </div>
        </div>
        <div class="logo-div"  v-if="logoDivFlag">
          <img src="@/assets/icon_logo_tabbar.png" />
        </div>
      </div>
      <!-- 头部右侧 -->
      <div class="right flex">
        <div class="entry-item align-center" @click="goSearch">
          <img src="@/assets/icon_search_tabbar.png" />
          搜索
        </div>
        <div class="entry-item align-center" @click="showPersonalCenter">
          <img src="@/assets/icon_my_tabbar.png" />
          {{userInfo.nickName?userInfo.nickName: '你好'}}
        </div>
        <div class="entry-item align-center" @click="goCart">
          <div class="trolley-div">
            <img src="@/assets/icon_trolley_tabbar.png" />
            <div class="trolley-num" v-if="cartNum>0">{{cartNum}}</div>
          </div>
          购物袋
        </div>
      </div>
    </header>
    <!-- /个人中心 -->
    <div class="personal-center-div" v-if="personalCenterFlag">
      <div class="mask" @click="closePersonalCenter"></div>
      <div class="personal-center">
        <p>个人中心</p>
        <div class="flex-start">
          <div class="photo" @click="goProfile">
            <img class="photo-img" v-if="userInfo.avatar " :src="userInfo.avatar" />
            <img class="photo-img" v-else src="@/assets/img_head_default.png" />
            <!-- avatar -->
          </div>
          <div class="text">
            <div class="flex-between">
              <div class="name" @click="goProfile">{{userInfo.nickName}}</div>
              <!-- <div class="name">妮蔻选料日记</div> -->
              <div class="footnotesBtn" @click="footnotesBtn">
                <img
                  class="footnotes-img"
                  src="@/assets/icon_footnotes_my.png"
                />
              </div>
            </div>
            <div class="flex-between">
              <div class="desc" @click="goProfile">{{userInfo.phonenumber}}</div>
              <!-- <div class="desc" @click="footnotesBtn">Nicol3032455</div> -->
              <div class="footnotesBtn" @click="footnotesBtn">足迹</div>
            </div>
          </div>
        </div>
        <div class="logOut" @click="logOut">
          退出
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations
} from 'vuex'
import {getCategoryList} from "@/commons/api/category";
export default {
  name: "Header",
  data() {
    return {
      // 头部左侧导航 菜单
      currentPath:"",
      categoryItemCheckeFlag: false,
      categoryDivFlag: false,
      categoriesList: [],
      // 头部左侧导航 logo
      logoDivFlag: false,
      
      // 个人中心
      personalCenterFlag: false,
    };
  },

  created() {
    this.getCategoryList();
    if (localStorage.getItem('personalCenter')) {
      this.showPersonalCenter();
      localStorage.removeItem('personalCenter');
    }
  },
  watch: {
    loginSign(newVal,oldVal){
      if(newVal){
        this.getCategoryList();
      }
    },
    $route: {
      handler(val) {
        //val即是this.$route
        //根据路由控制其他参数做不同处理

        console.log(val);
        console.log(val.path);
        this.personalCenterFlag = false

        if(val.path!='/boduan' && val.path!='/categoryList'){
          this.categoryItemCheckeFlag = false
        }else{
          this.categoryItemCheckeFlag = true
        }

        this.currentPath = val.path

        this.routePath(val.path);
      },
      immediate: true,
    },
    // $route(val) {
    //   //val即是this.$route
    //   //根据路由控制其他参数做不同处理
    //
    //   console.log(val);
    //   console.log(val.path);
    //   this.personalCenterFlag = false
    //
    //   if(val.path!='/boduan' && val.path!='/categoryList'){
    //      this.categoryItemCheckeFlag = false
    //   }else{
    //      this.categoryItemCheckeFlag = true
    //   }
    //
    //   this.currentPath = val.path
    //
    //   this.routePath(val.path);
    // },
  },

  computed: {
    ...mapState({
      cartNum: (state) => state.category.cartNum,
      categoryBaseCurrentId: (state) => state.category.categoryBaseCurrentId,
      loginSign: (state) => state.userInfo.loginSign,
      userInfo: (state) => state.userInfo.userInfo,
    }),
  },
  methods: {
    ...mapMutations([ 'setToken',
          'setUserInfo',
          'setLoginSign',
          'setCategoryList','setCategoryListThird','setCategoryBaseList','setCategoryBaseCurrentId','setCartNum']),
    //头部左侧显示
    routePath(path) {
      switch (path) {
        case "/boduan":
        case "/categoryList":
        case "/myCart":
        case "/search":
        case "/footnotes":
        case "/profile":
          this.categoryDivFlag = true;
          this.logoDivFlag = false;
          break;
        case "/compare":
        case "/goodsDetail":
          this.categoryDivFlag = false;
          this.logoDivFlag = true;
          break;
        default:
          this.categoryDivFlag = false;
          this.logoDivFlag = false;
          break;
      }
    },
    // 头部导航点击
    categoryClick(item,index) {
      this.setCategoryBaseCurrentId(item.id)
      if(this.currentPath!='/boduan' && this.currentPath!='/categoryList'){
          this.$router.push({
            path: "/boduan",
            query: {},
          });
      }


    },

    /**
     * 获取分类
     * @returns {Promise<void>}
     */
    async getCategoryList() {
      try {
        const {code, rows, msg} = await getCategoryList();
        if (code == 200) {
          this.categoriesList = rows.filter(item => item.level == 1);
          this.setCategoryList(rows)
          this.setCategoryBaseList(this.categoriesList)
          this.setCategoryBaseCurrentId(this.categoryBaseCurrentId || this.categoriesList[0].id)
        } else {

        }
      } catch (e) {
        console.log(e)
      }
    },

    showPersonalCenter() {
      const token = localStorage.getItem('token');
      if (token) {
        this.personalCenterFlag = true;
      } else {
        this.$router.push('/login?backUrl=/')
      }
    },
    closePersonalCenter() {
      this.personalCenterFlag = false;
    },
    //个人中心
    goProfile() {
      this.personalCenterFlag = false;
      this.$router.push({
        path: "/profile",
        query: {},
      });
    },
    //足迹
    footnotesBtn() {
      this.personalCenterFlag = false;
      this.$router.push({
        path: "/footnotes",
        query: {},
      });
    },
    logOut(){
      localStorage.setItem('token', '');
      this.setToken('');
      this.setLoginSign(false);
      this.setUserInfo({});
      this.$router.push({
        path: "/login",
        query: {},
      });
    },

    goSearch() {
      const token = localStorage.getItem('token');
      if (token) {
        this.$router.push('search')
      } else {
        this.$router.push('/login?backUrl=search')
      }
    },

    goCart() {
      const token = localStorage.getItem('token');
      if (token) {
        this.$router.push('myCart')
      } else {
        this.$router.push('/login?backUrl=myCart')
      }
    }

 
  },
  mounted() {
    // 获取菜单列表
    console.log(this.categoryBaseCurrentId);

  },
};
</script>

<style lang="less">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 90px;
  background: #fff;
  z-index: 999;
  .left {
    height: 100%;

    .category-div {
      padding-left: 25px;

      box-sizing: border-box;
      padding-bottom: 20px;
      .category-item {
        cursor: pointer;
        width: 60px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #555555;
        line-height: 90px;
        letter-spacing: 1px;
        margin-right: 40px;
        position: relative;
        // padding: 35px 15px 15px;

        &:hover::after {
          content: "";
          width: 18px;
          height: 2px;
          background: #555555;
          display: block;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .category-item-checked {
        // position: relative;
        background: #666666;
        color: #fff;
        &:hover::after {
          content: "";
          width: 0;
          height: 0;
        }
      }
    }

    .logo-div {
      padding-left: 40px;
      display: flex;
      align-items: center;
      img {
        width: 110px;
        height: 46px;
      }
    }
  }
  .right {
    padding-right: 40px;
    .entry-item {
      cursor: pointer;
      margin-left: 30px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #555555;
      line-height: 20px;
      letter-spacing: 1px;
      &:first-child {
        margin: 0;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .trolley-div{
        position: relative;
        .trolley-num{
          position: absolute;
          top: -9px;
          right: 0px;
          width: 18px;
          height: 18px;
          background: #555555;
          border: 1px solid #ffffff;
          border-radius: 50%;
          font-size: 11px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
}
.personal-center-div {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
  }
  .personal-center {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    background: #ffffff;
    z-index: 99999;
    padding: 0 40px 0 20px;
    p {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #3a3a3a;
      line-height: 28px;
      letter-spacing: 1.11px;
      margin: 50px 0 30px;
    }
    .photo {
      margin-right: 10px;
      .photo-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .text {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .name {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #3a3a3a;
        line-height: 22px;
        letter-spacing: 1px;
      }
      .desc {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 20px;
        letter-spacing: 1px;
      }
      .footnotes-img {
        width: 25px;
        height: 25px;
      }
    }
    .footnotesBtn {
      cursor: pointer;
    }
    .logOut{
      width: 360px;
      height: 46px;
      background: #3a3a3a;
      border-radius: 5px;
      position: absolute;
      left: 20px;
      bottom: 20px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 46px;
      letter-spacing: 0.01px;
    }
  }
}
</style>