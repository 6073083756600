import request from '../request';
import store from "@/store/index";
const METHOD = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE'
}
const pathPrefix = 'api/'

Object.freeze(METHOD)


/**
 * 获取分类列表
 * @param params
 * @returns {Promise<*>}
 */
 export async function getCategoryList(params) {
	return request.get(`${pathPrefix}category/list`, {params});
}

/**
 * 获取三级分类列表
 * @param params
 * @returns {Promise<*>}
 */
 export async function getCategoryList2Third(params) {
	return request.get(`${pathPrefix}category/list2Third`, {params});
}


/** 
 * 趋势列表C端使用  /dev-api/api/trend/list2C
 * @param params
 * @returns {Promise<*>}
 */
 export async function getTrendList2C(params) {
	return request.get(`${pathPrefix}trend/list2C`,  {params} );
}



// /** 
//  * 购物车列表 /dev-api/api/shopcar/list
//  * @param params
//  * @returns {Promise<*>}
//  */
//  export async function getShopcarList(params) {
// 	return request.get(`${pathPrefix}shopcar/list`,  {params} );
// }



/** 
 * 购物车列表 /dev-api/api/shopcar/list
 * @param params
 * @returns {Promise<*>}
 */
 export async function getShopcarList() {
	let params={
		pageNum:1,
		pageSize:200,
	}
	return request.get(`${pathPrefix}shopcar/list`,  {params} ).then(function(res) {
		const { code, rows, msg } = res
		if (code == 200) {
			if(rows&&rows.length>0){
			  let cartNum = 0
			  rows.map(item => {
				cartNum+=item.num
			  });
			//   this.setCartNum(cartNum)
			  store.commit('setCartNum', cartNum)
			}else{
			//   this.setCartNum(0)
			  store.commit('setCartNum', 0)

			}

		  }
		return res;
	});
}



/** 
 * 购物车列表 /dev-api/shopcar/{id}
 * @param params
 * @returns {Promise<*>}
 */
 export async function deletetShopcarList(params) {
	return request.delete(`${pathPrefix}shopcar/${params}`,);
}