<template>
  <div id="app" :class="{ padding: headerFlag }">
    <top-header v-if="headerFlag"></top-header>
    <router-view/>
  </div>
</template>

<script>
import header from '@/components/header.vue';
import footer from '@/components/footer.vue';
export default {
  name: 'Home',
  components: {
    topHeader: header,
    bottomFooter: footer,
  },
  data() {
    return {
      // 是否展示头部菜单
      headerFlag: true,
    };
  },
  watch: {
    $route(val) {
      console.log('app', val);
      //val即是this.$route
      this.headerFlag = !['/login', '/register', '/forgetPassword'].includes(val.path);
    },
  },
}
</script>

<style lang="less">
@import url("~@/commons/css/base");
input{outline: none;}
html, body, #app {
  min-width: 1080px;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #2c3e50;
  box-sizing: border-box;
  &.padding {
    padding-top: 90px;
  }
}
p{
  margin: 0;
  padding: 0;
}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
  }
  }
//nav {
//  padding: 30px;
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}

::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #B2B2B2!important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #B2B2B2!important;;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #B2B2B2!important;;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #B2B2B2!important;;
}
</style>
