import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import router from '@/router/index';
import { Message } from 'element-ui';

export const REQUEST_TOKEN_KEY = 'token';
export const STORAGE_TOKEN_KEY = 'token';

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_SERVER_URL,
  timeout: 5000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const { data = {}, status, statusText } = error.response;
    // console.log({ status });
    // // 403 无权限
    // if (status === 403) {
    //   notification.error({
    //     message: 'Forbidden',
    //     description: (data && data.message) || statusText,
    //   });
    // }
    // // 401 未登录/未授权
    // if (status === 401 && data.result && data.result.isLogin) {
    //   notification.error({
    //     message: 'Unauthorized',
    //     description: 'Authorization verification failed',
    //   });
    // }
  }
  return Promise.reject(error);
};

// 请求拦截器
const requestHandler = (
  config,
) => {
  const savedToken = localStorage.getItem(STORAGE_TOKEN_KEY);
  if (
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded' &&
    config.method !== 'get'
  ) {
    if (
      config.params &&
      !(config.params instanceof FormData) &&
      typeof config.params === 'object'
    ) {
      let form = new FormData();
      Object.keys(config.params).forEach(key => {
        form.append(key, config.params[key]);
      });
      config.params = form;
    }
  }

  if (savedToken) {
    config.headers[REQUEST_TOKEN_KEY] = savedToken;
  }

  return config;
};

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler);

// 响应拦截器
const responseHandler = (
  response
) => {
  const res = response.data;
  if (res.code === 401) {
    // Message.warning({
    //   message: '请重新登录',
    //   onClose() {
    //     router.replace('login');
    //   }
    // });

    if (router.currentRoute.path !== '/login') {
      router.replace('/login').catch(err => { console.log(err) });
    }

  } else if (res.code === '-1') {
    //message.error(res.msg)
    return res
  } 
  else {
    return res;
  }
};

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler);

export { AxiosResponse };



export const objectToFormData = (params) => {
  let form = new FormData();
  Object.keys(params).forEach(key => {
    form.append(key, params[key]);
  });
  return form;
};

export default request;
