import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './userInfo.js'
import category from './category.js'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
  },
  getters: {

  },
  mutations: {
    setToken(state, info) {
      state.token = info || '';
    },
  },
  actions: {

  },
  modules: {
    userInfo,
    category,
  },
  plugins: [createPersistedstate()]
})
