<template>
  <div>
    <a class="" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021038369号-2</a>
  </div>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {

    };
  },

  created() {

  },

  methods: {

  },
  mounted() {

  },
};
</script>

<style lang="less">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 90px;
  background: #fff;
  z-index: 999;
  .left {
    height: 100%;

    .category-div {
      padding-left: 25px;

      box-sizing: border-box;
      padding-bottom: 20px;
      .category-item {
        cursor: pointer;
        width: 60px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #555555;
        line-height: 90px;
        letter-spacing: 1px;
        margin-right: 40px;
        position: relative;
        // padding: 35px 15px 15px;

        &:hover::after {
          content: "";
          width: 18px;
          height: 2px;
          background: #555555;
          display: block;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .category-item-checked {
        // position: relative;
        background: #666666;
        color: #fff;
        &:hover::after {
          content: "";
          width: 0;
          height: 0;
        }
      }
    }

    .logo-div {
      padding-left: 40px;
      display: flex;
      align-items: center;
      img {
        width: 110px;
        height: 46px;
      }
    }
  }
  .right {
    padding-right: 40px;
    .entry-item {
      cursor: pointer;
      margin-left: 30px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #555555;
      line-height: 20px;
      letter-spacing: 1px;
      &:first-child {
        margin: 0;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .trolley-div{
        position: relative;
        .trolley-num{
          position: absolute;
          top: -9px;
          right: 0px;
          width: 18px;
          height: 18px;
          background: #555555;
          border: 1px solid #ffffff;
          border-radius: 50%;
          font-size: 11px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
}
.personal-center-div {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
  }
  .personal-center {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    background: #ffffff;
    z-index: 99999;
    padding: 0 40px 0 20px;
    p {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #3a3a3a;
      line-height: 28px;
      letter-spacing: 1.11px;
      margin: 50px 0 30px;
    }
    .photo {
      margin-right: 10px;
      .photo-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .text {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .name {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #3a3a3a;
        line-height: 22px;
        letter-spacing: 1px;
      }
      .desc {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 20px;
        letter-spacing: 1px;
      }
      .footnotes-img {
        width: 25px;
        height: 25px;
      }
    }
    .footnotesBtn {
      cursor: pointer;
    }
    .logOut{
      width: 360px;
      height: 46px;
      background: #3a3a3a;
      border-radius: 5px;
      position: absolute;
      left: 20px;
      bottom: 20px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 46px;
      letter-spacing: 0.01px;
    }
  }
}
</style>